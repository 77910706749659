// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.poster-container {
  border-radius: var(--chakra-radii-md);
  aspect-ratio: auto 603 / 887;
  overflow: hidden;
}
@supports not (aspect-ratio: 1/1) {
  .poster-container {
    height: 100%;
  }
}
.poster-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1600px) {
  .poster-container-sizing {
    width: 10vw;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1599px) {
  .poster-container-sizing {
    width: 20vw;
  }
}
@media screen and (min-width: 600px) and (max-width: 1099px) {
  .poster-container-sizing {
    width: 30vw;
  }
}
@media screen and (max-width: 599px) {
  .poster-container-sizing {
    width: 100vw;
  }
}
.focused-filled-white {
  background-color: #fff;
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./seplis/web/ui/index.less"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,4BAAA;EACA,gBAAA;AACJ;AACI;EAAA;IACI,YAAA;EAEN;AACF;AATA;EAUQ,iBAAA;EACA,WAAA;EACA,YAAA;AAER;AAGI;EAAA;IACI,WAAA;EAAN;AACF;AAEI;EAAA;IACI,WAAA;EACN;AACF;AACI;EAAA;IACI,WAAA;EAEN;AACF;AAAI;EAAA;IACI,YAAA;EAGN;AACF;AAAA;EACI,sBAAA;EACA,WAAA;AAEJ","sourcesContent":[".poster-container {\n    border-radius: var(--chakra-radii-md);\n    aspect-ratio: auto 603 / 887;\n    overflow: hidden;\n    \n    @supports not (aspect-ratio: 1/1) {\n        height: 100%;\n    }\n    \n    img {\n        object-fit: cover;\n        width: 100%;\n        height: 100%;\n    }\n}\n\n.poster-container-sizing {\n    @media screen and (min-width: 1600px) {\n        width: 10vw;\n    }\n\n    @media screen and (min-width: 1100px) and (max-width: 1599px) {        \n        width: 20vw;\n    }\n\n    @media screen and (min-width: 600px) and (max-width: 1099px) {\n        width: 30vw;\n    }\n\n    @media screen and (max-width: 599px) {\n        width: 100vw;\n    }\n}\n\n.focused-filled-white {\n    background-color: #fff;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
